
























































































































































































import Vue from 'vue';
import { mapGetters } from 'vuex';

export default Vue.extend({
  data() {
    return {
      form: {
        email: '',
        password: '',
        captchaToken: '',
      },
      error: null,
    };
  },
  async mounted() {
    await this.$store.dispatch('presenter/checkForLastSession');
    await this.$recaptchaLoaded();
    const recaptcha = this.$recaptchaInstance;
    recaptcha.showBadge();
  },
  computed: {
    ...mapGetters({
      errorMessage: 'getErrorMessage',
    }),
  },
  methods: {
    async submitForm() {
      const recaptcha = this.$recaptchaInstance;
      const token = await this.$recaptcha('login');
      this.form.captchaToken = token;
      this.$store.dispatch('auth/signInWithEmail', { ...this.form });
      recaptcha.hideBadge();
    },
  },
});
